<template>
  <div class="container">
    <div class="item" v-for="(itemVal, key) of items" :key="key">
      <b>
        {{ key }}
      </b>
      : {{ itemVal }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    items: Object,
  },
};
</script>

<style scoped lang="scss">
.item {
  max-width: 300px;
  line-height: 16px;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
