<template>
  <div>
    <div class="text-h4 pt-5">Меню</div>
    <div class="text-end">
      <v-btn color="accent" dark class="mb-2" @click="dialog = true">
        Создать
      </v-btn>
    </div>
    <v-dialog v-model="dialog" scrollable max-width="500px">
      <v-card class="pa-1">
        <v-card-title class="mb-5 align-start">
          <span v-if="editedIndex < 0" class="text-h5 mr-2">Создать меню</span>
          <span v-else class="text-h5mr-2"
            >Изменить меню
            <span class="accent--text">"{{ itemCurrentName }}"</span>
          </span>
        </v-card-title>

        <v-card-text>
          <v-row class="pt-4">
            <v-col cols="12" class="py-0">
              <v-tabs>
                <v-tab v-for="lang in languagesList" :key="lang.iso">
                  <div class="d-flex align-center">
                    <country-flag
                      :country="lang.icon"
                      class="ma-0 elevation-3"
                    />
                    {{ lang.name }}
                  </div>
                </v-tab>
                <v-tab-item
                  v-for="(lang, index) in languagesList"
                  :key="lang.iso + '_tab_menu'"
                  eager
                  class="pt-3"
                >
                  <v-text-field
                    :ref="`editedItem.name.${lang.iso}`"
                    v-model="editedItem.name[lang.iso]"
                    label="Название"
                    outlined
                    dense
                    :error="errors.name ? true : false"
                    :error-messages="errors.name"
                    @focus="errors.name = null"
                    @blur="
                      index === 0 ? slugPaste(editedItem.name[lang.iso]) : ''
                    "
                  ></v-text-field>
                </v-tab-item>
              </v-tabs>
            </v-col>
            <v-col cols="12" class="py-0">
              <v-select
                ref="editedItem.group"
                v-model="editedItem.group"
                :items="groups"
                :rules="[rules.required]"
                :disabled="editedIndex > -1 || editedChildren"
                label="Группа"
                item-text="name"
                item-value="value"
                item-color="secondary"
                outlined
                dense
              />
            </v-col>
            <v-col cols="12" class="py-0">
              <v-combobox
                ref="editedItem.route"
                v-model="editedItem.route"
                :items="routePages"
                :rules="[rules.required]"
                :error="!!errors.route"
                label="URL"
                item-text="name"
                item-value="route"
                item-color="secondary"
                persistent-hint
                hint="допустимые значения '#','/...','https://...',''http://...''"
                :return-object="false"
                outlined
                dense
                @focus="errors.route = null"
              >
                <template #selection="{ item }">
                  {{ routeName(item) }}
                </template>
              </v-combobox>
            </v-col>
            <v-col cols="12" class="py-0">
              <div class="d-inline-block">
                <v-switch
                  color="primary"
                  class="mt-0"
                  v-model="editedItem.visibility"
                  label="Видимость"
                ></v-switch>
              </div>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions class="pb-4">
          <v-spacer></v-spacer>
          <v-btn color="accent" outlined @click="close"> Отмена </v-btn>
          <v-btn
            color="accent"
            :ripple="false"
            @click="save"
            v-text="editedIndex > -1 ? 'Изменить' : 'Создать'"
          ></v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogDelete" max-width="500px">
      <template>
        <v-card class="pa-1">
          <v-card-title
            class="text-h5 text-center justify-center flex-column mb-5"
            >Удалить меню?<br />
            <span class="accent--text">{{ itemCurrentName }}</span>
          </v-card-title>

          <v-card-actions class="pb-4">
            <v-spacer></v-spacer>
            <v-btn color="accent" outlined @click="closeDelete">Отмена</v-btn>
            <v-btn color="accent" @click="deleteItemConfirm">OK</v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>
    <div v-for="(items, group) in items" :key="group">
      <v-data-table
        :headers="headers"
        :items="items.filter((item) => item.parent_id)"
        :items-per-page="-1"
        hide-default-footer
        :loading="loading"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <div class="text-h5">{{ groupName(group) }}</div>
            <v-spacer></v-spacer>
          </v-toolbar>
        </template>
        <template v-slot:item.name="{ item }">
          <v-row dense>
            <v-col v-if="item.ancestors.length >= 1" cols="auto">
              <v-btn icon small @click="move(item.id, 'up')"
                ><v-icon>mdi-arrow-up-bold</v-icon>
              </v-btn>
              <v-btn icon small @click="move(item.id, 'down')"
                ><v-icon>mdi-arrow-down-bold</v-icon>
              </v-btn>
            </v-col>
            <v-col cols="auto">
              <ancestors :ancestor="item" />
            </v-col>
          </v-row>
        </template>
        <template v-slot:item.values="{ item }">
          <MenuValues :items="item.name" />
        </template>
        <template v-slot:item.active="{ item }">
          <div class="d-inline-block">
            <v-switch
              v-model="item.visibility"
              color="primary"
              class="my-0"
              hide-details
              :ripple="false"
              @change="changeStatus(item)"
            ></v-switch>
          </div>
        </template>
        <template v-slot:item.add="{ item }">
          <v-btn
            v-if="item.ancestors.length < 2"
            small
            outlined
            :ripple="false"
            @click="createSubMenu(item)"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon small class="mr-2" @click="editItem(item)">
            mdi-pencil
          </v-icon>
          <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
import api from "../../../api";
import { mapGetters } from "vuex";
import rules from "@/mixins/rules";
import slug from "@/mixins/slug";
import CountryFlag from "vue-country-flag";
import Ancestors from "@/components/Content/Ancestors";
import copyValueObjs from "@/helpers/copyValueObjs";
import MenuValues from "@/components/Content/MenuValues.vue";

export default {
  name: "MenuEdit",
  mixins: [rules, slug],
  components: { MenuValues, CountryFlag, Ancestors },
  data() {
    return {
      dialog: false,
      dialogDelete: false,
      createSettings: null,
      headers: [
        {
          text: "Меню",
          align: "start",
          sortable: false,
          value: "name",
        },
        {
          text: "Значения",
          value: "values",
          align: "start",
          width: 300,
          sortable: false,
        },
        {
          text: "Видимость",
          value: "active",
          align: "center",
          width: 110,
          sortable: false,
        },
        {
          text: "Создать меню",
          value: "add",
          sortable: false,
          align: "center",
          width: 130,
        },
        {
          text: "Действия",
          value: "actions",
          sortable: false,
          align: "end",
          width: 100,
        },
      ],
      items: null,
      optionsTable: {},
      total: 0,
      editedItem: null,
      editedIndex: -1,
      editedChildren: false,
      loading: false,
      errors: {},
    };
  },

  computed: {
    ...mapGetters(["languagesList"]),
    itemCurrentName() {
      const preferredLang = "ru";
      const preferredItem = this.editedItem?.name?.[preferredLang];
      if (!preferredItem) {
        const filledItems = Object.values(this.editedItem?.name || {}).filter(
          (val) => val
        );
        return filledItems[0] || "";
      }
      return preferredItem;
    },
    groups() {
      return Object.entries(this.createSettings?.groups || {}).map(
        ([key, value]) => {
          return {
            value: key,
            name: value,
          };
        }
      );
    },
    groupName() {
      return (group) => {
        const gr = this.createSettings?.groups[group];
        return gr ? gr : "";
      };
    },
    routePages() {
      return this.createSettings?.entities?.Pages || [];
    },
    routeName() {
      return (route) => {
        const page = this.routePages.find((p) => p.route === route);
        return page ? page.name : route;
      };
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
  },
  created() {
    this.getItems();
    this.getCreateSettings();
    this.editedItem = { ...this.createItem() };
  },
  methods: {
    async editItem(item) {
      this.editedIndex = this.items[item.group].indexOf(item);
      this.editedItem = copyValueObjs(
        this.createItem(),
        this.items[item.group][this.editedIndex]
      );
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.items[item.group].indexOf(item);
      this.editedItem = this.items[item.group][this.editedIndex];
      this.dialogDelete = true;
    },

    async deleteItemConfirm() {
      await this.removeItem(this.editedItem.id);
      this.getItems();
      this.closeDelete();
    },

    async close() {
      this.dialog = false;
      await this.$nextTick();
      this.editedItem = { ...this.createItem() };
      this.editedIndex = -1;
      this.editedChildren = false;
      this.errors = {};
      this.resetErrors(this.editedItem, "editedItem");
    },

    async closeDelete() {
      this.dialogDelete = false;
      await this.$nextTick();
      this.editedItem = { ...this.createItem() };
      this.editedIndex = -1;
    },

    async save() {
      let formHasErrors = this.validationObject(this.editedItem, "editedItem");
      if (formHasErrors) {
        this.$store.dispatch("showSnackbar", {
          msg: "Заполните обязательные поля",
          color: "error",
          show: true,
        });
        return;
      }
      if (this.editedIndex > -1) {
        await this.updateItem();
        this.getItems();
      } else {
        await this.addItem();
        this.getItems();
      }
    },

    async getItems() {
      this.loading = true;
      try {
        const res = await api.menu.menu();
        this.items = res.data;
        this.loading = false;
      } catch (e) {
        console.error(e);
        this.loading = false;
      }
    },

    async addItem() {
      const data = this.editedItem;
      try {
        await api.menu.createMenu(data);
        this.$store.dispatch("showSnackbar", {
          msg: `Меню "${this.editedItem.name.ru}" создано`,
          color: "success",
          show: true,
        });
        this.close();
        this.getItems();
      } catch (e) {
        console.error(e);
        if (e?.response?.data?.errors) {
          this.errors = { ...e.response.data.errors };
        } else {
          this.$store.dispatch("showSnackbar", {
            msg: "Ошибка создания",
            color: "error",
            show: true,
          });
        }
      }
    },

    async updateItem() {
      const data = this.editedItem;
      try {
        await api.menu.updateMenu(data.id, data);
        this.$store.dispatch("showSnackbar", {
          msg: `Меню "${this.itemCurrentName}" изменено`,
          color: "success",
          show: true,
        });
        this.close();
      } catch (e) {
        console.error(e);
        if (e?.response?.data?.errors) {
          this.errors = { ...e.response.data.errors };
        } else {
          this.$store.dispatch("showSnackbar", {
            msg: "Ошибка редактирования",
            color: "error",
            show: true,
          });
        }
      }
    },

    async removeItem(id) {
      try {
        const nameDelete = this.editedItem.name.ru;
        await api.menu.removeMenu(id);
        this.$store.dispatch("showSnackbar", {
          msg: `Меню "${nameDelete}" удалено`,
          color: "success",
          show: true,
        });
      } catch (e) {
        console.error(e);
        this.$store.dispatch("showSnackbar", {
          msg: "Ошибка удаления",
          color: "error",
          show: true,
        });
      }
    },

    async changeStatus(item) {
      try {
        await api.menu.updateMenu(item.id, item);
      } catch (e) {
        console.error(e);
      }
    },

    createItem() {
      const keys = ["name"];
      let localeKeys = {};
      keys.forEach((key) => {
        let obj = {};
        this.languagesList.forEach((lang) => {
          obj[lang.iso] = "";
        });
        localeKeys[key] = obj;
      });
      return {
        ...localeKeys,
        group: "",
        route: "#",
        visibility: true,
      };
    },

    async getCreateSettings() {
      try {
        const res = await api.menu.createSettings();
        this.createSettings = res.data;
      } catch (e) {
        console.error(e);
      }
    },

    createSubMenu(item) {
      this.editedItem.parent_id = item.id;
      this.editedItem.group = item.group;
      this.editedChildren = true;
      this.dialog = true;
    },

    async move(id, action) {
      try {
        await api.menu.moveMenu(id, action);
        this.getItems();
      } catch (e) {
        console.error(e);
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
