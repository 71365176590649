<template>
  <v-row no-gutters>
    <v-col v-for="parent in parents" :key="parent.id" cols="auto">
      <div class="d-flex">
        <ancestors :ancestor="parent" />
        <v-icon v-if="parent.ancestors.length" x-small class="mx-2">
          mdi-arrow-right
        </v-icon>
      </div>
    </v-col>
    <v-col v-if="name" cols="auto">
      {{ name }}
    </v-col>
  </v-row>
</template>

<script>
import Ancestors from "@/components/Content/Ancestors";

export default {
  name: "Ancestors",
  components: { Ancestors },
  props: {
    ancestor: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    name() {
      if (this.ancestor?.parent_id) {
        const priorityLang = "ru";
        return this.ancestor?.name?.[priorityLang]
          ? this.ancestor.name[priorityLang]
          : Object.values(this.ancestor.name)[0] || null;
      } else {
        return null;
      }
    },
    parents() {
      return this.ancestor?.ancestors;
    },
  },
};
</script>

<style scoped></style>
